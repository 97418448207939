<!-- 招商 -->
<template>
  <div class="eg_hwef">
    <img v-for="(item, index) in list" :key="index" :src="item" alt="" />
  </div>
</template>

<script>
import url from "../../../config";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      list: [
        `${url.Url}zhangshangshouce_one.jpg`,
        `${url.Url}zhanoshangshouce_two.jpg`,
        `${url.Url}zhaoshangshouc_three.jpg`,
        `${url.Url}zhaoshangshouce_four.jpg`,
        `${url.Url}zhaoshangshou_five.jpg`,
        `${url.Url}zhaoshangshou_six.jpg`,
        `${url.Url}zhaoshangshou_qi.jpg`,
        `${url.Url}zhaoshangshauc_eight.jpg`,
        `${url.Url}zhaoshangshou_nine.jpg`,
        `${url.Url}shangshangshouce_ten.jpg`,
        // `${url.Url}zhaoshangshou_eleven.jpg`,
        `${url.Url}zhaoshangshou_welve.jpg`,
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.img_we {
}
.eg_hwef {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>